import {
  CHECKBOX_LEF, RADIO_GROUP, SELECT_INPUT, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const fields = {
    setting_fulfill_rules_warehouse_rules_apply_level: {
      type: RADIO_GROUP,
      label: '',
      list: [
        { value: 0, text: 'Warehouse rules apply at the organization level' },
        { value: 1, text: 'Warehouse rules apply at the warehouse level' },
      ],
      rules: 'required',
    },
    setting_fulfill_rules_sounds_play_a_sound_on_item_packing: {
      type: CHECKBOX_LEF,
      label: 'Play a sound on item packing',
      rules: 'required',
    },
    setting_fulfill_rules_sounds_play_a_sound_on_pick: {
      type: CHECKBOX_LEF,
      label: 'Play a sound on pick',
      rules: 'required',
    },
    setting_fulfill_rules_sounds_play_a_sound_on_container_selection: {
      type: CHECKBOX_LEF,
      label: 'Play a sound on container selection (pack)',
      rules: 'required',
    },
    setting_fulfill_rules_dispatch_minutes_after: {
      type: TEXT_INPUT,
      label: 'Minutes after a dispatch is marked as late',
      placeholder: 'Type...',
      rules: 'required',
    },
    setting_fulfill_rules_barcode_scanner_type: {
      type: SELECT_INPUT,
      label: 'Barcode Scanner Type',
      store: 'fullfilBarcodeScannerList',
      options: {
        value: 'id',
        label: 'name',
      },
      rules: 'required',
    },
    setting_fulfill_rules_overdue_duration: {
      type: TEXT_INPUT,
    },
    setting_fulfill_rules_idle_timeout: {
      type: TEXT_INPUT,
    },
    setting_fulfill_rules_quality_checks_maintenance_require_quality_check_inspection_on_pick: {
      type: CHECKBOX_LEF,
      label: 'Require Quality Check/Inspection on Pick',
      rules: 'required',
    },
    setting_fulfill_rules_quality_checks_maintenance_require_scanning_container_after_scanning_item_on_pack: {
      type: CHECKBOX_LEF,
      label: 'Require scanning container after scanning item on pack',
      rules: 'required',
    },
    setting_fulfill_rules_quality_checks_maintenance_require_quality_check_inspection_on_put_back: {
      type: CHECKBOX_LEF,
      label: 'Require Quality Check/Inspection on Put-back',
      rules: 'required',
    },
    setting_fulfill_rules_quality_checks_maintenance_require_scanning_containers_on_receive: {
      type: CHECKBOX_LEF,
      label: 'Require scanning containers on receive',
      rules: 'required',
    },
  }

  return {
    fields,
  }
}
