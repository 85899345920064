<template>
<div>
<b-row>
  <b-col>
    <div>
      <b-row>
        <b-col>
          <div class="d-flex flex-wrap">
            <b-skeleton
                width="380px"
                height="25px"
                style="border-radius: 5px; margin-right: 20px"
            />
            <b-skeleton
                width="380px"
                height="25px"
                style="border-radius: 5px"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </b-col>
</b-row>
  <p class="font-weight-bolder font-medium-5">
    {{ $t('Sounds') }}
  </p>
  <b-row class="mb-2">
    <b-col md="5">
      <b-skeleton
          width="380px"
          height="25px"
          style="border-radius: 5px"
      />
      <b-skeleton
          width="380px"
          height="25px"
          style="border-radius: 5px"
      />
    </b-col>
    <b-col md="7">
      <b-skeleton
          width="380px"
          height="25px"
          style="border-radius: 5px"
      />
    </b-col>
  </b-row>
  <p class="font-weight-bolder font-medium-5">
    {{ $t('Dispatch') }}
  </p>
  <b-row>
    <b-col md="6">
      <div
          v-for="field in ['setting_fulfill_rules_dispatch_minutes_after']"
          :key="field"
          style="height: 30px; margin-bottom: 10px"
      >
          <span
              class="font-weight-bolder d-block"
              style="margin-bottom: 5px; margin-left: 10px"
          >{{ fields[field].label }}</span>
        <b-skeleton
            height="30px"
            style="border-radius: 5px"
        />
      </div>
    </b-col>
  </b-row>
  <p class="font-weight-bolder font-medium-5 mt-3">
    {{ $t('Scanning') }}
  </p>
  <b-row>
    <b-col>
      <b-row>
        <b-col class="some-padding" md="11">
          <b-skeleton type="input" />
        </b-col>
      </b-row>
    </b-col>
    <b-col>
      <b-col class="some-padding" md="6" style="margin-bottom: 3px">
        <b-row class="align-items-center">
          <b-col class="col-md-7">
          </b-col>
          <b-col>
            <b-skeleton type="input" style="width: 49px; margin-left: 30px; height: 45px" class="my-0" />
          </b-col>
        </b-row>
      </b-col>
      <b-col class="some-padding" md="12" style="margin-bottom: 3px">
        <b-row class="align-items-center">
          <b-col class="col-md-3">
          </b-col>
          <b-col class="col-md-2 ml-n3 mr-1">
            <b-skeleton type="input" style="width: 49px; margin-left: 5px; height: 45px" class="my-0" />
          </b-col>
          <b-col class="col-md-2 ml-n2">
          </b-col>
        </b-row>
      </b-col>
    </b-col>
  </b-row>

  <p class="font-weight-bolder font-medium-5 ">
    {{ $t('Quality Checks & Maintenance') }}
  </p>
  <b-row class="mb-2">
    <b-col md="5">
      <b-skeleton
          width="380px"
          height="25px"
          style="border-radius: 5px"
      />
      <b-skeleton
          width="380px"
          height="25px"
          style="border-radius: 5px"
      />
    </b-col>
    <b-col md="7">
      <b-skeleton
          width="380px"
          height="25px"
          style="border-radius: 5px"
      />
      <b-skeleton
          width="380px"
          height="25px"
          style="border-radius: 5px"
      />
    </b-col>
  </b-row>
</div>
</template>
<script>
import {BCol, BRow, BSkeleton} from "bootstrap-vue";
import config from "@/views/settings/fulfillment/rules/rulesConfig";

export default {
  name: 'Skeleton',
  components: {BRow, BCol, BSkeleton},
  setup() {

    const { fields } = config()
    return {
      fields,
    }
  },
};
</script>
<style scoped lang="scss">

</style>