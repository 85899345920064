<template>
  <div>
    <skeleton v-if="loading" />
    <b-form v-else>
      <div class="d-flex justify-content-end">
        <feather-icon
          v-if="organizationRequiresEditConfirmation"
          v-b-tooltip.noninteractive.hover.bottom
          :title="$t('Edit')"
          class="cursor-pointer ml-1"
          :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
          size="36"
          @click="isEditAble"
        />
      </div>
      <validation-observer
        ref="ruleCreateVal"
      >
        <b-row>
          <b-col md="12">
            <component
              :is="fields[field].type"
              v-for="field in ['setting_fulfill_rules_warehouse_rules_apply_level']"
              :key="field"
              v-model="rulesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <p class="font-weight-bolder font-medium-5">
          {{ $t('Sounds') }}
        </p>
        <b-row class="mb-2">
          <b-col md="5">
            <component
              :is="fields[field].type"
              v-for="field in ['setting_fulfill_rules_sounds_play_a_sound_on_item_packing', 'setting_fulfill_rules_sounds_play_a_sound_on_pick']"
              :key="field"
              v-model="rulesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="7">
            <component
              :is="fields[field].type"
              v-for="field in ['setting_fulfill_rules_sounds_play_a_sound_on_container_selection']"
              :key="field"
              v-model="rulesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <p class="font-weight-bolder font-medium-5">
          {{ $t('Dispatch') }}
        </p>
        <b-row>
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in ['setting_fulfill_rules_dispatch_minutes_after']"
              :key="field"
              v-model="rulesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <p class="font-weight-bolder font-medium-5">
          {{ $t('Scanning') }}
        </p>
        <b-row>
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in ['setting_fulfill_rules_barcode_scanner_type']"
              :key="field"
              v-model="rulesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="6">
            <b-col
              v-for="field in ['setting_fulfill_rules_overdue_duration']"
              :key="field"
              class="some-padding"
              md="6"
            >
              <b-row class="align-items-center mb-1">
                <b-col class="col-md-7">
                  <span>{{ $t('Overdue Duration') }}</span>
                </b-col>
                <b-col class="ml-n2">
                  <component
                    :is="fields[field].type"
                    v-model="rulesForm[field]"
                    type="number"
                    :field="fields[field]"
                    :name="field"
                    class="my-0"
                    style="width: 70px"
                    :is-editable="isEdit"
                  />
                  <!--                    style="width: 49px; height: 45px"-->
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-for="field in ['setting_fulfill_rules_idle_timeout']"
              :key="field"
              class="some-padding"
              md="12"
            >
              <b-row class="align-items-center">
                <b-col class="col-md-3 mr-1">
                  <span>{{ $t('Idle Timeout') }}</span>
                </b-col>
                <b-col class="col-md-2 ml-n3 mr-2 ml-1">
                  <component
                    :is="fields[field].type"
                    v-model="rulesForm[field]"
                    type="number"
                    :field="fields[field]"
                    :name="field"
                    class="my-0"
                    style="width: 70px"
                    :is-editable="isEdit"
                  />
                  <!--                style="width: 49px; margin-left: 5px; height: 45px"-->
                </b-col>
                <b-col class="col-md-2 ml-n2">
                  <span class="mx-n2 pl-1">{{ $t('Minutes') }}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-col>
        </b-row>
        <p class="font-weight-bolder font-medium-5">
          {{ $t('Quality Checks & Maintenance') }}
        </p>
        <b-row>
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in [
                'setting_fulfill_rules_quality_checks_maintenance_require_quality_check_inspection_on_pick',
                'setting_fulfill_rules_quality_checks_maintenance_require_scanning_container_after_scanning_item_on_pack'
              ]"
              :key="field"
              v-model="rulesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in [
                'setting_fulfill_rules_quality_checks_maintenance_require_quality_check_inspection_on_put_back',
                'setting_fulfill_rules_quality_checks_maintenance_require_scanning_containers_on_receive'
              ]"
              :key="field"
              v-model="rulesForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="routeLink"
              >
                {{ $t('Back to Setting') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import {
  BButton, BCol, BForm, BRow, VBTooltip,
} from 'bootstrap-vue'
import Skeleton from '@/views/settings/fulfillment/rules/components/Skeleton.vue'
import { required } from '@/libs/validations/validations'
import { ValidationObserver } from 'vee-validate'
import config from '@/views/settings/fulfillment/rules/rulesConfig'
import { scrollToError } from '@core/utils/utils'
import store from '@/store'

export default {
  name: 'RulesForm',
  components: {
    Skeleton, BRow, BCol, BButton, BForm, ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      error: {},
      loading: true,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    rulesForm() {
      return this.$store.state[this.MODULE_NAME].rulesForm
    },
    rulesFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingFulfillmentRulesFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.rulesForm) !== JSON.stringify(this.rulesFormClone)
    },
  },
  created() {
    this.load()
  },
  beforeRouteLeave(to, from, next) {
    this.showConfirmationModal(next)
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    routeLink() {
      this.$router.push({ name: 'settings' })
    },
    getProps(fieldName) {
      const dataOF = {
        field: {
          ...this.fields[fieldName],
        },
        name: fieldName,
      }
      return dataOF
    },
    load() {
      this.template = {}
      this.templateClone = {}
      this.$store.dispatch(`${this.MODULE_NAME}/get`).then(res => {
        const { data } = res.data
        const rules = this.mappingFields(Object.keys(this.fields), data, {
          setting_fulfill_rules_barcode_scanner_type: data.setting_fulfill_rules_barcode_scanner_type,
          setting_fulfill_rules_dispatch_minutes_after: data.setting_fulfill_rules_dispatch_minutes_after,
          setting_fulfill_rules_idle_timeout: data.setting_fulfill_rules_idle_timeout,
          setting_fulfill_rules_overdue_duration: data.setting_fulfill_rules_overdue_duration,
          setting_fulfill_rules_warehouse_rules_apply_level: data.setting_fulfill_rules_warehouse_rules_apply_level,

          setting_fulfill_rules_sounds_play_a_sound_on_container_selection: data.setting_fulfill_rules_sounds_play_a_sound_on_container_selection === '1',
          setting_fulfill_rules_sounds_play_a_sound_on_item_packing: data.setting_fulfill_rules_sounds_play_a_sound_on_item_packing === '1',
          setting_fulfill_rules_sounds_play_a_sound_on_pick: data.setting_fulfill_rules_sounds_play_a_sound_on_pick === '1',
          setting_fulfill_rules_quality_checks_maintenance_require_scanning_container_after_scanning_item_on_pack: data.setting_fulfill_rules_quality_checks_maintenance_require_scanning_container_after_scanning_item_on_pack === '1',
          setting_fulfill_rules_quality_checks_maintenance_require_quality_check_inspection_on_pick: data.setting_fulfill_rules_quality_checks_maintenance_require_quality_check_inspection_on_pick === '1',
          setting_fulfill_rules_quality_checks_maintenance_require_quality_check_inspection_on_put_back: data.setting_fulfill_rules_quality_checks_maintenance_require_quality_check_inspection_on_put_back === '1',
          setting_fulfill_rules_quality_checks_maintenance_require_scanning_containers_on_receive: data.setting_fulfill_rules_quality_checks_maintenance_require_scanning_containers_on_receive === '1',
        })
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, rules)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_FULFILLMENT_RULES_FORM_CLONE`, rules)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
    submit() {
      this.onSubmit = true

      this.$refs.ruleCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.rulesForm
            const rulesForm = this.mappingFields(Object.keys(this.fields), data, {
              setting_fulfill_rules_barcode_scanner_type: data.setting_fulfill_rules_barcode_scanner_type.id,
            })
            console.log('rulesForm', rulesForm)
            this.sendNotification(this, { ...rulesForm }, `${this.MODULE_NAME}/update`)
              .then(() => {
                this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
                this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_FULFILLMENT_RULES_FORM_CLONE`, data)
                // this.$router.replace({
                //   name: 'settings-fulfillment-ruless',
                // })
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.ruleCreateVal)
          }
        })
    },
    loader() {
      this.$refs.ruleCreateVal.reset()
      // const smth = JSON.parse(this.labelsFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, this.rulesFormClone)
    },
    clear() {
      this.$refs.ruleCreateVal.reset()
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME = 'rules'

    const { fields } = config()
    return {
      fields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
<style>
#setting_fulfill_rules_warehouse_rules_apply_level > div:last-child {
  margin-left: 50px;
}
@media all and (max-width: 1113px) {
  #setting_fulfill_rules_warehouse_rules_apply_level > div:last-child {
    margin-left: 0;
  }
}
</style>
